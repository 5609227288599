import { getTotalProductPrice, convertToEuros, isB2B, isB2C, ProductType, getChoicesQuantity, getLowerRangeProductPrice } from "common";
import React from "react";
import { useSelector } from "react-redux";
import { selectProfileInfos } from "store/account/profile/profileSelectors";
import { useIntl } from "react-intl";
export function ProductPrice({ product, productCart }) {
    const intl = useIntl();
    const profile = useSelector(selectProfileInfos);
    const getDefaultProductPrice = (priceKey) => {
        var _a, _b;
        switch (product.type) {
            case ProductType.CHOICE_PRODUCT:
                const range = ((product === null || product === void 0 ? void 0 : product.choices) || []).map(choice => {
                    return getLowerRangeProductPrice(product, choice.priceRanges, 1, priceKey);
                });
                return Math.min(...range);
            case ProductType.SIMPLE_PRODUCT:
                return product[priceKey];
            case ProductType.SIMPLE_GROUP:
            case ProductType.CHOICE_GROUP:
            case ProductType.SCALE_GROUP:
                return (_b = (_a = product.elements) === null || _a === void 0 ? void 0 : _a.reduce((prev, curr) => {
                    return prev[priceKey] < curr[priceKey] ? prev : curr;
                })) === null || _b === void 0 ? void 0 : _b[priceKey];
        }
    };
    const showPrice = !!(isB2C(profile) ? getTotalProductPrice(productCart, 'b2cPriceVat') : getTotalProductPrice(productCart, 'price'));
    const totalProductPrice = () => {
        if (isB2C(product) && (isB2C(profile) || !profile)) {
            return `Total : ${convertToEuros(getTotalProductPrice(productCart, 'b2cPriceVat'))}€ TTC`;
        }
        if (isB2B(product) && isB2B(profile)) {
            return `Total : ${convertToEuros(getTotalProductPrice(productCart, 'price'))}€ HT`;
        }
        if (isB2B(product) && isB2C(profile)) {
            return intl.formatMessage({
                id: `Ce produit est à destination des professionnels uniquement`,
                defaultMessage: 'Ce produit est à destination des professionnels uniquement.'
            });
        }
        if (isB2C(product) && isB2B(profile)) {
            return intl.formatMessage({
                id: `Ce produit est à destination des particuliers uniquement`,
                defaultMessage: 'Ce produit est à destination des particuliers uniquement.'
            });
        }
        return `${convertToEuros(getTotalProductPrice(productCart, 'price'))}€ HT`;
    };
    const defaultProductPrice = () => {
        if (isB2C(product) && (!profile || isB2C(profile))) {
            return intl.formatMessage({
                id: 'A partir de',
                defaultMessage: 'A partir de'
            }) + ' ' + convertToEuros(getDefaultProductPrice('b2cPriceVat')) + intl.formatMessage({ id: '€ TTC', defaultMessage: '€ TTC' });
        }
        if (isB2B(product) && isB2B(profile)) {
            return intl.formatMessage({
                id: 'A partir de',
                defaultMessage: 'A partir de'
            }) + ' ' + convertToEuros(getDefaultProductPrice('price')) + intl.formatMessage({ id: '€ HT', defaultMessage: '€ HT' });
        }
        if (isB2B(product) && isB2C(profile)) {
            return intl.formatMessage({
                id: `Ce produit est à destination des professionnels uniquement`,
                defaultMessage: 'Ce produit est à destination des professionnels uniquement.'
            });
        }
        if (isB2C(product) && isB2B(profile)) {
            return intl.formatMessage({
                id: `Ce produit est à destination des particuliers uniquement`,
                defaultMessage: 'Ce produit est à destination des particuliers uniquement.'
            });
        }
        return intl.formatMessage({
            id: 'A partir de',
            defaultMessage: 'A partir de'
        }) + ' ' + convertToEuros(getDefaultProductPrice('price')) + intl.formatMessage({ id: '€ HT', defaultMessage: '€ HT' });
    };
    return (React.createElement("span", { className: "scale-price-info" },
        (productCart.type === ProductType.SCALE_GROUP && getChoicesQuantity(productCart) > 0) && React.createElement("span", null,
            "Quantit\u00E9 : ",
            getChoicesQuantity(productCart)),
        React.createElement("span", null, showPrice ? totalProductPrice() : defaultProductPrice())));
}

import { Decrement, Increment } from "components/Quantity";
import { useFormikContext } from "formik";
import React from "react";
import { isStockValid } from "common";
import "./ProductQuantity.scss";
import { FormattedMessage } from "react-intl";
export function ProductQuantity() {
    const { values, setFieldValue } = useFormikContext();
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "k-qtyselector simple-product pack-selector" },
            React.createElement("p", { className: "k-qtyselector--title" },
                React.createElement(FormattedMessage, { id: "Choisissez votre quantit\u00E9", defaultMessage: "Choisissez votre quantit\u00E9" })),
            React.createElement("div", { className: "k-qtyselector--action" },
                React.createElement(Decrement, { decrement: () => (values.quantity > 0) && setFieldValue('quantity', +values.quantity - 1) }),
                React.createElement("input", { className: "quantity", name: "quantity", value: values.quantity || 0, min: 0, onChange: (e) => {
                        setFieldValue('quantity', e.target.value);
                    } }),
                React.createElement(Increment, { increment: () => setFieldValue('quantity', +values.quantity + 1) })),
            !isStockValid(values) &&
                React.createElement("p", { className: "k-qtyselector--warning" },
                    React.createElement(FormattedMessage, { id: "La quantit\u00E9 choisie est sup\u00E9rieure au stock du produit", defaultMessage: "La quantit\u00E9 choisie est sup\u00E9rieure au stock du produit" })))));
}

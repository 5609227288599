var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));

// src/auth/expiration.interceptor.ts
function createExpirationInterceptor(callback) {
  return [
    (response) => {
      if (!!response.headers["x-auth-exp"]) {
        callback(+response.headers["x-auth-exp"]);
      }
      return response;
    },
    (error) => {
      throw error;
    }
  ];
}

// src/auth/authSelectors.ts
import { createSelector } from "reselect";
var selectAuthState = () => createSelector(
  (state) => state.auth,
  (state) => state
);
var selectExp = () => createSelector(
  selectAuthState(),
  (auth) => auth == null ? void 0 : auth.exp
);
var selectIsAuthenticated = () => createSelector(
  selectExp(),
  (exp) => {
    return exp !== null && +exp > (/* @__PURE__ */ new Date()).getTime();
  }
);

// src/auth/authTypes.ts
var AuthActionTypes = /* @__PURE__ */ ((AuthActionTypes2) => {
  AuthActionTypes2["LOGIN_ATTEMPT"] = "LOGIN_ATTEMPT";
  AuthActionTypes2["LOGIN_SUCCESS"] = "LOGIN_SUCCESS";
  AuthActionTypes2["LOGIN_FAILURE"] = "LOGIN_FAILURE";
  AuthActionTypes2["LOGOUT_ATTEMPT"] = "LOGOUT_ATTEMPT";
  AuthActionTypes2["LOGOUT_SUCCESS"] = "LOGOUT_SUCCESS";
  AuthActionTypes2["LOGOUT_FAILURE"] = "LOGOUT_FAILURE";
  AuthActionTypes2["UPDATE_EXPIRATION"] = "UPDATE_EXPIRATION";
  return AuthActionTypes2;
})(AuthActionTypes || {});

// src/auth/authActions.ts
var loginAttempt = (credentials) => ({
  type: "LOGIN_ATTEMPT" /* LOGIN_ATTEMPT */,
  payload: credentials
});
var loginSuccess = (data) => ({
  type: "LOGIN_SUCCESS" /* LOGIN_SUCCESS */,
  payload: data
});
var loginFailure = (errors) => ({
  type: "LOGIN_FAILURE" /* LOGIN_FAILURE */,
  payload: errors
});
var logoutAttempt = () => ({
  type: "LOGOUT_ATTEMPT" /* LOGOUT_ATTEMPT */
});
var logoutSuccess = () => ({
  type: "LOGOUT_SUCCESS" /* LOGOUT_SUCCESS */
});
var logoutFailure = () => ({
  type: "LOGOUT_FAILURE" /* LOGOUT_FAILURE */
});
var updateExpiration = (exp) => ({
  type: "UPDATE_EXPIRATION" /* UPDATE_EXPIRATION */,
  payload: exp
});

// src/auth/authSagas.ts
import { put, take, all, fork, call, select } from "redux-saga/effects";
import { push } from "connected-react-router";

// src/utils/api.extractor.ts
function extractResponseData(response) {
  return response == null ? void 0 : response.data;
}
function extractResponseErrors(error) {
  var _a;
  const errors = (_a = error == null ? void 0 : error.response) == null ? void 0 : _a.data.errors;
  return errors ? errors : { frontGenericError: "Le service est indisponible" };
}

// src/auth/authSagas.ts
var getPathname = (state) => state.router.location.pathname;
var loginSagaFactory = (options) => {
  return function* () {
    while (true) {
      const action = yield take("LOGIN_ATTEMPT" /* LOGIN_ATTEMPT */);
      const pathname = yield select(getPathname);
      const { response, error } = yield call(options.loginMethod, action.payload);
      if (response) {
        yield put(loginSuccess(extractResponseData(response)));
        if (pathname === "/authentification") {
          yield put(push(options.loginUrl));
        } else {
          yield put(push(pathname));
        }
      } else {
        yield put(loginFailure(extractResponseErrors(error)));
      }
    }
  };
};
var logoutSagaFactory = (options) => {
  return function* () {
    while (true) {
      try {
        yield take("LOGOUT_ATTEMPT" /* LOGOUT_ATTEMPT */);
        yield call(options.logoutMethod);
        yield put(logoutSuccess());
        yield put(push(options.logoutUrl));
      } catch (e) {
        yield put(logoutFailure());
      }
    }
  };
};
var authSagas = (options) => {
  return function* () {
    yield all([
      fork(loginSagaFactory(options)),
      fork(logoutSagaFactory(options))
    ]);
  };
};

// src/utils/is-browser.ts
function isBrowser() {
  return typeof window !== "undefined" && Object.keys(window).length > 0;
}
function getEnvVar(name) {
  try {
    return isBrowser() ? window.__env__[name] : process.env[name];
  } catch (error) {
    console.log("getEnvVar.catch", error);
    return "";
  }
}

// src/utils/storage.ts
var setItem = ({ key, value }) => {
  return isBrowser() ? localStorage == null ? void 0 : localStorage.setItem(key, value) : null;
};
var getItem = (key) => {
  return isBrowser() ? localStorage == null ? void 0 : localStorage.getItem(key) : null;
};
var removeItem = (key) => {
  return isBrowser() ? localStorage == null ? void 0 : localStorage.removeItem(key) : null;
};

// src/auth/authReducer.ts
var authLocalState = JSON.parse(getItem("auth") || "{}");
var initialState = {
  exp: (authLocalState == null ? void 0 : authLocalState.exp) || null
};
var authReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_EXPIRATION" /* UPDATE_EXPIRATION */:
      return __spreadProps(__spreadValues({}, state), {
        exp: action.payload
      });
    case "LOGOUT_SUCCESS" /* LOGOUT_SUCCESS */:
      return __spreadProps(__spreadValues({}, state), {
        exp: null
      });
    default:
      return state;
  }
};

// src/utils/sync.saga.ts
import { take as take2, call as call2, select as select2, all as all2 } from "redux-saga/effects";
var STORAGE_DATA_EXPIRATION_KEY = "data_expiration";
function sync(options) {
  return function* () {
    while (true) {
      yield take2("*");
      const state = yield select2();
      yield all2(options.keys.map((key) => call2(setItem, { key, value: JSON.stringify(state == null ? void 0 : state[key]) })));
      const now = /* @__PURE__ */ new Date();
      const delay = getEnvVar("RAZZLE_LOCAL_STORAGE_EXPIRES_IN") || 1;
      const expiresAt = /* @__PURE__ */ new Date();
      expiresAt.setMinutes(expiresAt.getMinutes() + delay);
      yield call2(setItem, { key: STORAGE_DATA_EXPIRATION_KEY, value: expiresAt.toString() });
    }
  };
}
function handleExpired(options) {
  return function* () {
    const now = /* @__PURE__ */ new Date();
    let expiresAt = yield call2(getItem, STORAGE_DATA_EXPIRATION_KEY);
    expiresAt = new Date(expiresAt);
    if (expiresAt < now) {
      yield all2(options.keys.map((key) => call2(removeItem, key)));
    }
  };
}

// src/interfaces/product.interface.ts
var PRODUCT_VAT = 20;
function isChoiceProduct({ type }) {
  return type === "choice_product" /* CHOICE_PRODUCT */ || type === "choice_group" /* CHOICE_GROUP */ || type === "scale_group" /* SCALE_GROUP */;
}
function isGroupProduct({ type }) {
  return type === "simple_group" /* SIMPLE_GROUP */ || type === "choice_group" /* CHOICE_GROUP */ || type === "scale_group" /* SCALE_GROUP */;
}
function isChoiceProductType({ type }) {
  return type === "choice_product" /* CHOICE_PRODUCT */;
}
function isWeightProduct({ type }) {
  return type === "simple_product" /* SIMPLE_PRODUCT */ || type === "choice_product" /* CHOICE_PRODUCT */ || type === "scale_group" /* SCALE_GROUP */;
}
var ProductType = /* @__PURE__ */ ((ProductType2) => {
  ProductType2["SIMPLE_PRODUCT"] = "simple_product";
  ProductType2["SIMPLE_GROUP"] = "simple_group";
  ProductType2["CHOICE_PRODUCT"] = "choice_product";
  ProductType2["CHOICE_GROUP"] = "choice_group";
  ProductType2["SCALE_GROUP"] = "scale_group";
  return ProductType2;
})(ProductType || {});

// src/interfaces/admin.interface.ts
var AdminRights = /* @__PURE__ */ ((AdminRights2) => {
  AdminRights2["ADMIN"] = "admin";
  AdminRights2["SECONDARY"] = "secondary";
  AdminRights2["PICKER"] = "picker";
  return AdminRights2;
})(AdminRights || {});

// src/interfaces/user.interface.ts
var Countries = /* @__PURE__ */ ((Countries2) => {
  Countries2["FRANCE"] = "FR";
  Countries2["BELGIQUE"] = "BE";
  return Countries2;
})(Countries || {});
var UserRights = /* @__PURE__ */ ((UserRights2) => {
  UserRights2["CLASSIC"] = "classic";
  UserRights2["PREMIUM"] = "premium";
  return UserRights2;
})(UserRights || {});
var BusinessType = /* @__PURE__ */ ((BusinessType2) => {
  BusinessType2["B2B"] = "B2B";
  BusinessType2["B2C"] = "B2C";
  return BusinessType2;
})(BusinessType || {});

// src/interfaces/tag.interface.ts
var NUANCIER_TAG_NAME = "NUANCIER";

// src/interfaces/shipping.interface.ts
var ShippingModes = /* @__PURE__ */ ((ShippingModes2) => {
  ShippingModes2["CLASSIC"] = "classic";
  ShippingModes2["PICKUP"] = "pickup";
  ShippingModes2["PREDICT"] = "predict";
  ShippingModes2["B2CPICKUP"] = "b2cPickup";
  ShippingModes2["B2CPREDICT"] = "b2cPredict";
  return ShippingModes2;
})(ShippingModes || {});
var Days = /* @__PURE__ */ ((Days2) => {
  Days2["MONDAY"] = "lundi";
  Days2["TUESDAY"] = "mardi";
  Days2["WEDNESDAY"] = "mercredi";
  Days2["THURSDAY"] = "jeudi";
  Days2["FRIDAY"] = "vendredi";
  Days2["SATURDAY"] = "samedi";
  Days2["SUNDAY"] = "dimanche";
  return Days2;
})(Days || {});

// src/interfaces/order.interface.ts
var PaymentMode = /* @__PURE__ */ ((PaymentMode2) => {
  PaymentMode2["TRANSFER"] = "TRANSFER";
  PaymentMode2["CB"] = "CB";
  PaymentMode2["BRIDGE"] = "BRIDGE";
  return PaymentMode2;
})(PaymentMode || {});
var LyraStatus = /* @__PURE__ */ ((LyraStatus2) => {
  LyraStatus2["PAID"] = "PAID";
  LyraStatus2["RUNNING"] = "RUNNING";
  LyraStatus2["UNPAID"] = "UNPAID";
  return LyraStatus2;
})(LyraStatus || {});
var OrderStatus = /* @__PURE__ */ ((OrderStatus2) => {
  OrderStatus2["CREATED"] = "CREATED";
  OrderStatus2["PENDING"] = "PENDING";
  OrderStatus2["PREPARE"] = "PREPARE";
  OrderStatus2["PAID"] = "PAID";
  OrderStatus2["CANCELLED"] = "CANCELLED";
  OrderStatus2["SENT"] = "SENT";
  OrderStatus2["OFFLINE"] = "OFFLINE";
  OrderStatus2["CREDIT"] = "CREDIT";
  return OrderStatus2;
})(OrderStatus || {});
var OrderDetailedStatus = /* @__PURE__ */ ((OrderDetailedStatus2) => {
  OrderDetailedStatus2["ACCEPTED"] = "ACCEPTED";
  OrderDetailedStatus2["AUTHORISED"] = "AUTHORISED";
  OrderDetailedStatus2["CAPTURED"] = "CAPTURED";
  OrderDetailedStatus2["PARTIALLY_AUTHORISED"] = "PARTIALLY_AUTHORISED";
  OrderDetailedStatus2["AUTHORISED_TO_VALIDATE"] = "AUTHORISED_TO_VALIDATE";
  OrderDetailedStatus2["WAITING_AUTHORISATION"] = "WAITING_AUTHORISATION";
  OrderDetailedStatus2["WAITING_AUTHORISATION_TO_VALIDATE"] = "WAITING_AUTHORISATION_TO_VALIDATE";
  OrderDetailedStatus2["REFUSED"] = "REFUSED";
  OrderDetailedStatus2["ERROR"] = "ERROR";
  OrderDetailedStatus2["CANCELLED"] = "CANCELLED";
  OrderDetailedStatus2["EXPIRED"] = "EXPIRED";
  return OrderDetailedStatus2;
})(OrderDetailedStatus || {});
function getTotalPrice(base) {
  return base.totalVatWithPromo + (base.fees || 0);
}
function getTotalPriceByCountry(base, country) {
  return country === "FR" /* FRANCE */ ? base.totalVatWithPromo + (base.fees || 0) : base.totalWithPromo + (base.fees || 0);
}

// src/interfaces/promotion.interface.ts
var PromotionType = /* @__PURE__ */ ((PromotionType2) => {
  PromotionType2["CART_PERCENT"] = "cart_percent";
  PromotionType2["CART_FIXED"] = "cart_fixed";
  return PromotionType2;
})(PromotionType || {});
var isPromoCart = (type) => type === "cart_fixed" /* CART_FIXED */ || type === "cart_percent" /* CART_PERCENT */;
var isPromoPercent = (type) => type === "cart_percent" /* CART_PERCENT */;
var isPromoFixed = (type) => type === "cart_fixed" /* CART_FIXED */;
var isPromoWithAmount = (type) => type === "cart_percent" /* CART_PERCENT */ || type === "cart_fixed" /* CART_FIXED */;

// src/interfaces/quotation.interface.ts
function getTotalPriceForQuotation(base) {
  return base.totalVatWithPromo + (base.fees || 0);
}
function getTotalPriceByCountryForQuotation(base, country) {
  return country === "FR" /* FRANCE */ ? base.totalVatWithPromo + (base.fees || 0) : base.totalWithPromo + (base.fees || 0);
}

// src/interfaces/translation.interface.ts
var Languages = /* @__PURE__ */ ((Languages2) => {
  Languages2["FRENCH"] = "fr";
  Languages2["ENGLISH"] = "en";
  Languages2["GERMAN"] = "de";
  Languages2["SPANISH"] = "es";
  Languages2["PORTUGUESE"] = "po";
  Languages2["ITALIAN"] = "it";
  return Languages2;
})(Languages || {});

// src/interfaces/guest-suite-experience.interface.ts
var ExperienceExtraData = class {
};

// src/utils/price.helpers.ts
function convertToEuros(price) {
  return (price / 100).toFixed(2);
}
function convertToCents(price) {
  return typeof price === "number" ? price * 100 : price;
}
function convertToKilos(weight) {
  return weight / 1e3;
}
function convertToVat(price) {
  return Math.round(typeof price === "number" ? price + price * PRODUCT_VAT / 100 : price);
}
function convertFromVatToHT(price) {
  return Math.round(typeof price === "number" ? price / (1 + PRODUCT_VAT / 100) : price);
}
var computeProductPrices = (products, key) => {
  return products.reduce((total, product) => {
    switch (product.type) {
      case "simple_product" /* SIMPLE_PRODUCT */:
        total += typeof product[key] === "number" ? product[key] * product.quantity : 0;
        break;
      case "choice_product" /* CHOICE_PRODUCT */:
        total += getChoiceProductPrice(product, key);
        break;
      case "simple_group" /* SIMPLE_GROUP */:
      case "choice_group" /* CHOICE_GROUP */:
        total += typeof product.element[key] === "number" ? product.element[key] * product.quantity : 0;
        break;
      case "scale_group" /* SCALE_GROUP */:
        total += typeof product.element[key] === "number" ? product.element[key] * getChoicesQuantity(product) * product.quantity : 0;
    }
    return Math.round(total);
  }, 0);
};
var computeProductExcludedPrices = (products, promotion) => {
  var _a, _b, _c, _d;
  if (!!promotion && ((_a = promotion == null ? void 0 : promotion.productsExcluded) == null ? void 0 : _a.length) > 0 && ((_b = promotion == null ? void 0 : promotion.tagsExcluded) == null ? void 0 : _b.length) > 0) {
    const prodsExcluded = products.filter((product) => promotion.productsExcluded.find(({ _id }) => _id === product._id));
    const tagsExcluded = products.filter((product) => promotion.tagsExcluded.find(({ _id }) => _id.toString() === product.tag));
    const productsExcluded = Array.from(new Set(prodsExcluded.concat(tagsExcluded)));
    return computeProductPrices(productsExcluded, "price");
  } else if (!!promotion && ((_c = promotion == null ? void 0 : promotion.productsExcluded) == null ? void 0 : _c.length) > 0) {
    const productsExcluded = products.filter((product) => promotion.productsExcluded.find(({ _id }) => _id === product._id));
    return computeProductPrices(productsExcluded, "price");
  } else if (!!promotion && ((_d = promotion == null ? void 0 : promotion.tagsExcluded) == null ? void 0 : _d.length) > 0) {
    const productsExcluded = products.filter((product) => promotion.tagsExcluded.find(({ _id }) => _id.toString() === product.tag));
    return computeProductPrices(productsExcluded, "price");
  } else {
    return 0;
  }
};
var computeCartPrices = (cart, user) => {
  const total = (user == null ? void 0 : user.businessType) === "B2B" /* B2B */ ? computeProductPrices(cart.products, "price") : computeProductPrices(cart.products, "b2cPrice");
  const totalVat = convertToVat(total);
  const totalPriceProductsExcluded = computeProductExcludedPrices(cart.products, cart.promotion);
  const totalOnlyPromo = total - totalPriceProductsExcluded;
  if (!cart.promotion) {
    return {
      total,
      totalVat,
      totalWithPromo: total,
      totalVatWithPromo: totalVat,
      promoReduction: 0
    };
  }
  let totalWithPromo = null;
  let totalVatWithPromo = null;
  let promoReduction = null;
  switch (cart.promotion.type) {
    case "cart_fixed" /* CART_FIXED */:
      totalWithPromo = cart.promotion.amount < totalOnlyPromo ? Math.round(totalOnlyPromo - cart.promotion.amount + totalPriceProductsExcluded) : 0;
      totalVatWithPromo = convertToVat(totalWithPromo + totalPriceProductsExcluded);
      return {
        total,
        totalVat,
        totalWithPromo,
        totalVatWithPromo,
        promoReduction: cart.promotion.amount < totalOnlyPromo ? cart.promotion.amount : totalOnlyPromo
      };
    case "cart_percent" /* CART_PERCENT */:
      promoReduction = Math.round(totalOnlyPromo * cart.promotion.amount / 100);
      totalWithPromo = Math.round(total - promoReduction);
      totalVatWithPromo = convertToVat(totalWithPromo);
      return {
        total,
        totalWithPromo,
        totalVat,
        totalVatWithPromo,
        promoReduction
      };
    default:
      return {
        total,
        totalWithPromo: total,
        totalVat,
        totalVatWithPromo: totalVat,
        promoReduction: 0
      };
  }
};
var getRangeProductPrice = (product, priceRanges, quantity, priceKey) => {
  const priceRange = getRangeFromQuantity(priceRanges, quantity);
  const price = priceRange ? priceRange[priceKey] : product[priceKey];
  return (price || 0) * (quantity || 0);
};
var getLowerRangeProductPrice = (product, priceRanges, quantity, priceKey) => {
  const priceRange = getRangeFromQuantity(priceRanges, quantity);
  const price = priceRange ? priceRange[priceKey] : product[priceKey];
  return priceRanges.reduce((minPrice, entry) => {
    return entry.price < minPrice ? entry.price : minPrice;
  }, (price || 0) * (quantity || 0));
};
var getRangeFromQuantity = (priceRanges, quantity) => {
  if (!priceRanges) {
    return null;
  }
  return priceRanges.find((priceRange) => quantity >= priceRange.from && quantity <= priceRange.to);
};
var getChoiceProductPrice = (product, priceKey) => {
  return ((product == null ? void 0 : product.choices) || []).filter((choice) => choice.quantity > 0).reduce((acc, choice) => {
    acc += getRangeProductPrice(product, choice.priceRanges, choice.quantity, priceKey);
    return acc;
  }, 0) || 0;
};
var getTotalProductPrice = (cartProduct, priceKey) => {
  var _a, _b;
  switch (cartProduct.type) {
    case "choice_product" /* CHOICE_PRODUCT */:
      return getChoiceProductPrice(cartProduct, priceKey) * cartProduct.quantity;
    case "simple_product" /* SIMPLE_PRODUCT */:
      return cartProduct[priceKey] * cartProduct.quantity;
    case "simple_group" /* SIMPLE_GROUP */:
    case "choice_group" /* CHOICE_GROUP */:
      return ((_a = cartProduct.element) == null ? void 0 : _a[priceKey]) * cartProduct.quantity;
    case "scale_group" /* SCALE_GROUP */:
      const choicesScaleQuantity = getChoicesQuantity(cartProduct);
      return ((_b = cartProduct.element) == null ? void 0 : _b[priceKey]) * choicesScaleQuantity * cartProduct.quantity;
  }
};

// src/utils/product.helpers.ts
var productEqual = (product, productToCompare) => {
  var _a, _b, _c, _d, _e, _f, _g, _h, _i, _j;
  if ((product == null ? void 0 : product._id) !== (productToCompare == null ? void 0 : productToCompare._id) || ((_a = product.element) == null ? void 0 : _a._id) !== ((_b = productToCompare.element) == null ? void 0 : _b._id) || ((_c = product.choices) == null ? void 0 : _c.length) !== ((_d = productToCompare.choices) == null ? void 0 : _d.length) || haveFreeProducts(product) !== haveFreeProducts(productToCompare) || ((_e = getFreeChoices(product)) == null ? void 0 : _e.count) !== ((_f = getFreeChoices(productToCompare)) == null ? void 0 : _f.count))
    return false;
  if (haveFreeProducts(product)) {
    for (const freeChoice of (_g = getFreeChoices(product)) == null ? void 0 : _g.ids) {
      const index = (_i = (_h = getFreeChoices(productToCompare)) == null ? void 0 : _h.ids) == null ? void 0 : _i.findIndex((productToCompareFreeChoice) => {
        return (productToCompareFreeChoice == null ? void 0 : productToCompareFreeChoice._id) === (freeChoice == null ? void 0 : freeChoice._id) && (productToCompareFreeChoice == null ? void 0 : productToCompareFreeChoice.quantity) === (freeChoice == null ? void 0 : freeChoice.quantity);
      });
      if (index === -1)
        return false;
    }
  }
  for (const choice of product.choices) {
    const index = (_j = productToCompare.choices) == null ? void 0 : _j.findIndex((productToCompareChoice) => {
      return (productToCompareChoice == null ? void 0 : productToCompareChoice._id) === (choice == null ? void 0 : choice._id) && ((productToCompareChoice == null ? void 0 : productToCompareChoice.quantity) === (choice == null ? void 0 : choice.quantity) || ((productToCompareChoice == null ? void 0 : productToCompareChoice.quantity) === 0 || (choice == null ? void 0 : choice.quantity) === 0));
    });
    if (index === -1)
      return false;
  }
  return true;
};
var findSameProductIndex = (products, payload) => {
  return products.findIndex((product) => productEqual(product, payload));
};
var freeChoicesLeft = (product) => {
  var _a, _b;
  return (((_a = getFreeChoices(product)) == null ? void 0 : _a.ids) || []).reduce((acc, curr) => {
    return acc - (curr.quantity || 0);
  }, ((_b = getFreeChoices(product)) == null ? void 0 : _b.count) || 0);
};
var choicesLeft = (product) => {
  return product.choices.reduce((acc, curr) => {
    return acc - (curr.quantity || 0);
  }, getChoicesCount(product));
};
var choicesScaleLeft = (product) => {
  return product.choices.reduce((acc, curr) => {
    return acc - (curr.quantity || 0);
  }, getChoicesScaleCount(product));
};
var getChoicesScaleCount = (product) => {
  var _a, _b;
  return !!((_a = product.element) == null ? void 0 : _a.maxScale) ? ((_b = product.element) == null ? void 0 : _b.maxScale) || 0 : 1e3;
};
var getChoicesCount = (product) => {
  var _a;
  return ((_a = product.element) == null ? void 0 : _a.count) || product.count || 0;
};
var getProductWeight = (product) => {
  var _a;
  return ((_a = product.element) == null ? void 0 : _a.weight) || (product == null ? void 0 : product.weight) || 0;
};
var getChoiceProductWeight = (product) => {
  var _a;
  return ((_a = product.choices) == null ? void 0 : _a.filter((choice) => choice.quantity > 0).reduce((acc, choice) => {
    acc += !!choice.weight && choice.weight > 0 ? (choice == null ? void 0 : choice.weight) * choice.quantity : (product == null ? void 0 : product.weight) * choice.quantity;
    return acc;
  }, 0)) || 0;
};
var getProductTotalWeightWithFree = (product) => {
  var _a, _b;
  let totalWeight = 0;
  if (product.type === "choice_product" /* CHOICE_PRODUCT */)
    totalWeight += getChoiceProductWeight(product);
  if (product.type === "scale_group" /* SCALE_GROUP */)
    totalWeight += getChoicesQuantity(product) * getProductWeight(product);
  else if (product.type !== "choice_product" /* CHOICE_PRODUCT */)
    totalWeight += getProductWeight(product) * product.quantity;
  if (haveFreeProducts(product)) {
    for (const free of (_a = getFreeChoices(product)) == null ? void 0 : _a.ids) {
      totalWeight += ((free == null ? void 0 : free.weight) || 0) * (free.quantity || 0);
    }
  }
  if (haveIncludedProducts(product)) {
    for (const included of (_b = getFreeIncluded(product)) == null ? void 0 : _b.ids) {
      totalWeight += ((included == null ? void 0 : included.weight) || 0) * (included.quantity || 0);
    }
  }
  return totalWeight;
};
var getProductsTotalWeightWithFree = (products) => {
  const total = products.reduce((totalWeight, product) => {
    return totalWeight + getProductTotalWeightWithFree(product);
  }, 0);
  return convertToKilos(total).toFixed(0);
};
var getProductsTotalWeightWithFreeDecagrammes = (products) => {
  const total = products.reduce((totalWeight, product) => {
    return totalWeight + getProductTotalWeightWithFree(product);
  }, 0);
  return total / 10;
};
var isElementValid = (product) => {
  return !!product.element;
};
var isQuantityValid = (product) => {
  return product.quantity > 0;
};
var isProductStockValid = (product, prod) => {
  return !!prod ? (prod == null ? void 0 : prod.stock) > 0 && product.quantity <= (prod == null ? void 0 : prod.stock) : product.stock > 0 && product.quantity <= (product == null ? void 0 : product.stock);
};
var isSimpleGroupStockValid = (product, prod) => {
  var _a, _b;
  const element = (_a = prod == null ? void 0 : prod.elements) == null ? void 0 : _a.find((el) => {
    var _a2, _b2, _c;
    return ((_a2 = el._id) == null ? void 0 : _a2.toString()) === ((_c = (_b2 = product.element) == null ? void 0 : _b2._id) == null ? void 0 : _c.toString());
  });
  return !!prod ? (prod == null ? void 0 : prod.stock) > 0 && (product == null ? void 0 : product.quantity) * ((element == null ? void 0 : element.units) || 1) <= (prod == null ? void 0 : prod.stock) : (product == null ? void 0 : product.stock) > 0 && (product == null ? void 0 : product.quantity) * (((_b = product.element) == null ? void 0 : _b.units) || 1) <= (product == null ? void 0 : product.stock);
};
var isChoiceProductStockValid = (product, prod) => {
  var _a, _b;
  const choices = (_a = product.choices) == null ? void 0 : _a.filter((choice) => !!choice.quantity);
  const defaultChoices = !!prod ? (_b = prod == null ? void 0 : prod.choices) == null ? void 0 : _b.filter((choice) => (choice == null ? void 0 : choice.stock) > 0) : product.choices.filter((choice) => (choice == null ? void 0 : choice.stock) > 0);
  const validChoices = choices.map((choice) => isChoiceStockValid(choice, product, prod)).includes(false);
  return !validChoices && (defaultChoices == null ? void 0 : defaultChoices.length) > 0;
};
var isChoiceStockValid = (choice, product, prod) => {
  var _a;
  const selectedChoice = (_a = prod == null ? void 0 : prod.choices) == null ? void 0 : _a.find((ch) => {
    var _a2, _b;
    return ((_a2 = ch._id) == null ? void 0 : _a2.toString()) === ((_b = choice._id) == null ? void 0 : _b.toString());
  });
  return !!prod ? (selectedChoice == null ? void 0 : selectedChoice.stock) > 0 && choice.quantity * ((product == null ? void 0 : product.quantity) || 1) <= (selectedChoice == null ? void 0 : selectedChoice.stock) : choice.stock > 0 && (choice.quantity || 0) * ((product == null ? void 0 : product.quantity) || 1) <= choice.stock;
};
var isFreeChoicesStockValid = (freeChoice, products) => {
  const allProducts = extractChoicesFromProducts(products);
  const selectedChoice = allProducts.find((product) => {
    var _a, _b;
    return ((_a = product._id) == null ? void 0 : _a.toString()) === ((_b = freeChoice._id) == null ? void 0 : _b.toString());
  });
  return (selectedChoice == null ? void 0 : selectedChoice.stock) > 0 && (freeChoice == null ? void 0 : freeChoice.quantity) <= (selectedChoice == null ? void 0 : selectedChoice.stock);
};
var isStockValid = (product, prod) => {
  switch (product.type) {
    case "choice_group" /* CHOICE_GROUP */:
    case "choice_product" /* CHOICE_PRODUCT */:
    case "scale_group" /* SCALE_GROUP */:
      return isChoiceProductStockValid(product, prod);
    case "simple_group" /* SIMPLE_GROUP */:
      return isSimpleGroupStockValid(product, prod);
    case "simple_product" /* SIMPLE_PRODUCT */:
      return isProductStockValid(product, prod);
    default:
      return true;
  }
};
var isFreeChoicesValid = (product, totalExpected) => {
  var _a;
  const freeChoices = ((_a = getFreeChoices(product)) == null ? void 0 : _a.ids) || [];
  const total = freeChoices.reduce((acc, curr) => {
    return acc + ((curr == null ? void 0 : curr.quantity) || 0);
  }, 0);
  return total === totalExpected;
};
var getChoicesQuantity = (product) => {
  var _a;
  return ((_a = product.choices) == null ? void 0 : _a.reduce((acc, choice) => {
    return acc + (choice.quantity || 0);
  }, 0)) || 0;
};
var getElementScaleGroup = (cartProduct, product) => {
  var _a;
  const choicesQuantity = getChoicesQuantity(cartProduct);
  const element = (_a = product.elements) == null ? void 0 : _a.find((element2) => element2.minScale <= choicesQuantity && element2.maxScale >= choicesQuantity);
  return choicesQuantity > 0 ? element : null;
};
var isChoicesValid = (product, totalExpected) => {
  const total = product.choices.reduce((acc, curr) => {
    return acc + (curr.quantity || 0);
  }, 0);
  if (product.type === "choice_product" /* CHOICE_PRODUCT */ && !product.restricted) {
    return total >= 1;
  }
  return total === totalExpected;
};
var isChoicesScaleValid = (product, minScale, maxScale) => {
  const total = product.choices.reduce((acc, curr) => {
    return acc + (curr.quantity || 0);
  }, 0);
  return !!maxScale ? total >= minScale && total <= maxScale : total >= minScale;
};
var getFreeChoices = (product) => {
  var _a;
  return isGroupProduct(product) ? (_a = product.element) == null ? void 0 : _a.freeChoices : product == null ? void 0 : product.freeChoices;
};
var getFreeIncluded = (product) => {
  var _a;
  return isGroupProduct(product) ? (_a = product.element) == null ? void 0 : _a.freeIncluded : product == null ? void 0 : product.freeIncluded;
};
var isSameChoice = (choice, choiceToCompare) => {
  var _a, _b;
  return ((_a = choice._id) == null ? void 0 : _a.toString()) === ((_b = choiceToCompare._id) == null ? void 0 : _b.toString()) && choice.code === choiceToCompare.code;
};
var isAllSameChoice = (choice, choiceToCompare) => {
  var _a;
  return ((_a = choice.filter((cartChoice) => {
    return choiceToCompare.findIndex((productChoice) => isSameChoice(cartChoice, productChoice)) !== -1;
  })) == null ? void 0 : _a.length) === (choice == null ? void 0 : choice.length);
};
var haveFreeProducts = (product) => {
  var _a;
  return (((_a = getFreeChoices(product)) == null ? void 0 : _a.ids) || []).length > 0;
};
var haveIncludedProducts = (product) => {
  var _a;
  return (((_a = getFreeIncluded(product)) == null ? void 0 : _a.ids) || []).length > 0;
};
var isCartProductValid = (product, prod) => {
  var _a;
  switch (product.type) {
    case "choice_group" /* CHOICE_GROUP */:
      return isChoicesValid(product, (_a = product.element) == null ? void 0 : _a.count) && isStockValid(product);
    case "choice_product" /* CHOICE_PRODUCT */:
      return isChoicesValid(product, product.count) && isStockValid(product);
    case "simple_group" /* SIMPLE_GROUP */:
      return isElementValid(product) && isStockValid(product);
    case "simple_product" /* SIMPLE_PRODUCT */:
      return isQuantityValid(product) && isStockValid(product);
    case "scale_group" /* SCALE_GROUP */:
      const selectedElementScaleProduct = getElementScaleGroup(product, prod);
      return !selectedElementScaleProduct ? false : isChoicesScaleValid(product, selectedElementScaleProduct.minScale, selectedElementScaleProduct.maxScale) && isStockValid(product);
    default:
      return true;
  }
};
var isCartFreeProductsValid = (product) => {
  var _a;
  return haveFreeProducts(product) ? isFreeChoicesValid(product, (_a = getFreeChoices(product)) == null ? void 0 : _a.count) : true;
};
var editProductCart = (products, index, payload, indexProd) => {
  if (indexProd !== -1) {
    products[indexProd].quantity += products[index].quantity;
  } else {
    products.push(payload);
    payload.quantity = products[index].quantity;
  }
  products.splice(index, 1);
  return products;
};
var getOrdersTraduction = (value) => {
  switch (value) {
    case "CREATED" /* CREATED */:
      return "Sauvegard\xE9e";
    case "PAID" /* PAID */:
      return "Pay\xE9e";
    case "PENDING" /* PENDING */:
      return "En attente de paiement";
    case "PREPARE" /* PREPARE */:
      return "En cours de pr\xE9paration";
    case "SENT" /* SENT */:
      return "Envoy\xE9e";
    case "CANCELLED" /* CANCELLED */:
      return "Annul\xE9e";
    case "OFFLINE" /* OFFLINE */:
      return "Paiement offline";
    case "CREDIT" /* CREDIT */:
      return "Avoir";
    default:
  }
};
var getOrderPaymentTraduction = (value) => {
  switch (value) {
    case "CB" /* CB */: {
      return "Carte Bancaire";
    }
    case "BRIDGE" /* BRIDGE */: {
      return "Bridge";
    }
    case "TRANSFER" /* TRANSFER */: {
      return "Virement";
    }
    default:
  }
};
var extractProducts = (order) => {
  const extractOrderableFreeProducts = (product, quantity) => {
    var _a, _b, _c, _d;
    const acc = [];
    if (product.freeIncluded && ((_b = (_a = product.freeIncluded) == null ? void 0 : _a.ids) == null ? void 0 : _b.length) > 0) {
      acc.push(...product.freeIncluded.ids.map((choice) => __spreadProps(__spreadValues({}, choice), {
        quantity: choice.quantity * quantity
      })));
    }
    if (product.freeChoices && ((_d = (_c = product.freeChoices) == null ? void 0 : _c.ids) == null ? void 0 : _d.length) > 0) {
      acc.push(...product.freeChoices.ids.filter((choice) => choice.quantity > 0).map((choice) => __spreadProps(__spreadValues({}, choice), {
        quantity: choice.quantity * quantity
      })));
    }
    return acc;
  };
  return order == null ? void 0 : order.products.reduce((acc, product) => {
    var _a;
    switch (product.type) {
      case "simple_product" /* SIMPLE_PRODUCT */:
        acc.push(product);
        acc.push(...extractOrderableFreeProducts(product, product.quantity));
        break;
      case "simple_group" /* SIMPLE_GROUP */:
        acc.push(__spreadProps(__spreadValues({}, product == null ? void 0 : product.element), { billingId: product.billingId, stock: product.stock, quantity: product.quantity }));
        acc.push(...extractOrderableFreeProducts(product == null ? void 0 : product.element, product.quantity));
        break;
      case "choice_product" /* CHOICE_PRODUCT */:
        acc.push(...product.choices.filter((choice) => choice.quantity > 0).map((choice) => __spreadProps(__spreadValues({}, choice), {
          quantity: choice.quantity * product.quantity,
          price: getRangeProductPrice(product, choice.priceRanges, choice.quantity, "price"),
          priceVat: getRangeProductPrice(product, choice.priceRanges, choice.quantity, "priceVat"),
          name: (product == null ? void 0 : product.name) + " - " + choice.name
        })));
        acc.push(...extractOrderableFreeProducts(product, product.quantity));
        break;
      case "choice_group" /* CHOICE_GROUP */:
        acc.push(__spreadProps(__spreadValues({}, product == null ? void 0 : product.element), { name: product.name + " - " + ((_a = product == null ? void 0 : product.element) == null ? void 0 : _a.name), quantity: product.quantity, type: product.type }));
        acc.push(...product.choices.filter((choice) => choice.quantity > 0).map((choice) => __spreadProps(__spreadValues({}, choice), {
          quantity: choice.quantity * product.quantity
        })));
        acc.push(...extractOrderableFreeProducts(product == null ? void 0 : product.element, product.quantity));
        break;
      case "scale_group" /* SCALE_GROUP */:
        acc.push(__spreadProps(__spreadValues({}, product == null ? void 0 : product.element), { name: product.name, quantity: getChoicesQuantity(product), type: product.type }));
        acc.push(...product.choices.filter((choice) => choice.quantity > 0).map((choice) => {
          var _a2, _b;
          return __spreadProps(__spreadValues({}, choice), {
            quantity: choice.quantity * product.quantity,
            price: (_a2 = product == null ? void 0 : product.element) == null ? void 0 : _a2.price,
            priceVat: (_b = product == null ? void 0 : product.element) == null ? void 0 : _b.priceVat,
            name: product.name + " - " + choice.name
          });
        }));
        acc.push(...extractOrderableFreeProducts(product == null ? void 0 : product.element, product.quantity));
        break;
      default:
        break;
    }
    return acc;
  }, []).reduce((acc, product) => {
    acc.push(product);
    return acc;
  }, []);
};
var extractProductsToUpdate = (product) => {
  var _a, _b, _c, _d, _e, _f;
  let productList = [];
  switch (product.type) {
    case "simple_product" /* SIMPLE_PRODUCT */:
      productList.push(product);
      break;
    case "simple_group" /* SIMPLE_GROUP */:
      productList.push(product);
      (_a = product.elements) == null ? void 0 : _a.map((element) => productList.push(element));
      break;
    case "choice_product" /* CHOICE_PRODUCT */:
      productList.push(product);
      (_b = product.choices) == null ? void 0 : _b.map((choice) => productList.push(choice));
      break;
    case "choice_group" /* CHOICE_GROUP */:
      productList.push(product);
      (_c = product.elements) == null ? void 0 : _c.map((element) => productList.push(element));
      (_d = product.choices) == null ? void 0 : _d.map((choice) => productList.push(choice));
      break;
    case "scale_group" /* SCALE_GROUP */:
      productList.push(product);
      (_e = product.elements) == null ? void 0 : _e.map((element) => productList.push(element));
      (_f = product.choices) == null ? void 0 : _f.map((choice) => productList.push(choice));
      break;
  }
  return productList;
};
var extractChoicesFromProducts = (products) => {
  return products.reduce((acc, product) => {
    switch (product.type) {
      case "simple_product" /* SIMPLE_PRODUCT */:
        acc.push(product);
        break;
      case "choice_product" /* CHOICE_PRODUCT */:
        acc.push(product);
        acc.push(...product == null ? void 0 : product.choices);
        break;
      case "simple_group" /* SIMPLE_GROUP */:
      case "choice_group" /* CHOICE_GROUP */:
      case "scale_group" /* SCALE_GROUP */:
        acc.push(product);
        acc.push(...product == null ? void 0 : product.elements);
        acc.push(...product == null ? void 0 : product.choices);
        break;
    }
    return acc;
  }, []);
};
var getProductElementPrice = (element, businessType) => {
  return businessType === "B2B" /* B2B */ ? element == null ? void 0 : element.price : element == null ? void 0 : element.b2cPrice;
};
var getProductElementPriceVat = (element, businessType) => {
  return businessType === "B2B" /* B2B */ ? element == null ? void 0 : element.priceVat : element == null ? void 0 : element.b2cPriceVat;
};
var getProductPrice = (product, businessType) => {
  return businessType === "B2B" /* B2B */ ? product == null ? void 0 : product.price : product == null ? void 0 : product.b2cPrice;
};
var getProductPriceVat = (product, businessType) => {
  return businessType === "B2B" /* B2B */ ? product == null ? void 0 : product.priceVat : product == null ? void 0 : product.b2cPriceVat;
};
var getOrderStatusColor = (order) => {
  switch (order == null ? void 0 : order.status) {
    case "PENDING": {
      return "#4E51F2";
    }
    case "CREATED": {
      return "#25D31A";
    }
    case "PAID": {
      return "#0E6609";
    }
    case "OFFLINE": {
      return "#0E6609";
    }
    case "CANCELLED": {
      return "#EF2016";
    }
    case "SENT": {
      return "#931AD3";
    }
    case "PREPARE": {
      return "#EF9D16";
    }
    case "CREDIT": {
      return "#FFC300";
    }
  }
};
var getOrderStatusAsOptions = () => {
  return Object.values(OrderStatus).map((value) => {
    let label = "";
    switch (value) {
      default:
      case "CREATED" /* CREATED */:
        label = "Sauvegard\xE9e";
        break;
      case "PAID" /* PAID */:
        label = "Pay\xE9e";
        break;
      case "PENDING" /* PENDING */:
        label = "En attente de paiement";
        break;
      case "PREPARE" /* PREPARE */:
        label = "En cours de pr\xE9paration";
        break;
      case "SENT" /* SENT */:
        label = "Envoy\xE9e";
        break;
      case "CANCELLED" /* CANCELLED */:
        label = "Annul\xE9e";
        break;
      case "OFFLINE" /* OFFLINE */:
        label = "Paiement offline";
        break;
      case "CREDIT" /* CREDIT */:
        label = "Avoir";
    }
    return { value, label };
  });
};

// src/utils/form.helpers.ts
function objectToFormData(data) {
  const flatten = flattenObject(data);
  return Object.keys(flatten).reduce((acc, curr) => {
    const value = flatten[curr];
    value && acc.append(curr, value);
    return acc;
  }, new FormData());
}
function flattenObject(body, key = "") {
  return Object.keys(body || {}).reduce((acc, curr) => {
    const index = key ? key + "[" + curr + "]" : curr;
    if (typeof body[curr] === "object" && !(body[curr] instanceof File)) {
      acc = __spreadValues(__spreadValues({}, acc), flattenObject(body[curr], index));
    } else {
      acc[index] = body[curr];
    }
    return acc;
  }, {});
}

// src/utils/user.helpers.ts
var getCountryByCode = (country) => {
  switch (country) {
    case "FR" /* FRANCE */:
      return "france";
    case "BE" /* BELGIQUE */:
      return "belgique";
    default:
      return "";
  }
};
var getBusinessTypeName = (businessType) => {
  switch (businessType) {
    case "B2B" /* B2B */:
      return "Professionnel";
    case "B2C" /* B2C */:
      return "Particulier";
    default:
      return "";
  }
};

// src/utils/businessType.helpers.ts
function isB2B(value) {
  var _a;
  return Array.isArray(value == null ? void 0 : value.businessType) ? (_a = value == null ? void 0 : value.businessType) == null ? void 0 : _a.includes("B2B" /* B2B */) : (value == null ? void 0 : value.businessType) === "B2B" /* B2B */;
}
function isB2C(value) {
  var _a;
  return Array.isArray(value == null ? void 0 : value.businessType) ? (_a = value == null ? void 0 : value.businessType) == null ? void 0 : _a.includes("B2C" /* B2C */) : (value == null ? void 0 : value.businessType) === "B2C" /* B2C */;
}

// src/utils/map.ts
function fromMapToArray(data) {
  return Object.values(data);
}
function fromArrayToMap(data, key) {
  return (data || []).reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
}
function mergeMaps(...maps) {
  return Object.assign({}, ...maps);
}
function mergeMapsWithExisting(source, updates, key) {
  const sourceArray = fromMapToArray(source);
  const updatedSourceArray = sourceArray.map((item) => {
    var _a;
    const update = (_a = updates[item[key]]) != null ? _a : {};
    return __spreadValues(__spreadValues({}, item), update);
  }).reduce((acc, item) => {
    acc[item[key]] = item;
    return acc;
  }, {});
  return mergeMaps(updates, updatedSourceArray);
}

// src/metastate/metaStateReducer.ts
var initialState2 = {
  pendings: {},
  errors: {}
};
var metaStateReducer = (state = initialState2, action) => {
  const actionData = action.type.match(/^(.*)_(ATTEMPT|SUCCESS|FAILURE)/);
  if ((actionData == null ? void 0 : actionData.length) !== 3) {
    return state;
  }
  const key = actionData[1];
  const type = actionData[2];
  switch (type) {
    case "ATTEMPT":
      return __spreadProps(__spreadValues({}, state), {
        pendings: __spreadProps(__spreadValues({}, state.pendings), { [key]: true }),
        errors: __spreadProps(__spreadValues({}, state.errors), { [key]: null })
      });
    case "SUCCESS":
      return __spreadProps(__spreadValues({}, state), {
        pendings: __spreadProps(__spreadValues({}, state.pendings), { [key]: false }),
        errors: __spreadProps(__spreadValues({}, state.errors), { [key]: null })
      });
    case "FAILURE":
      const errors = action.payload;
      return __spreadProps(__spreadValues({}, state), {
        pendings: __spreadProps(__spreadValues({}, state.pendings), { [key]: false }),
        errors: __spreadProps(__spreadValues({}, state.errors), { [key]: errors })
      });
    default:
      return state;
  }
};

// src/metastate/metaStateSelectors.ts
import { createSelector as createSelector2 } from "reselect";
var selectMetaState = createSelector2(
  (state) => state.metaState,
  (state) => state
);
var selectPendingState = createSelector2(
  selectMetaState,
  (state) => state.pendings
);
var selectErrorsState = createSelector2(
  selectMetaState,
  (state) => state.errors
);
var selectPending = (type) => {
  return createSelector2(
    selectPendingState,
    (pendings) => !!pendings[type]
  );
};
var selectErrors = (type) => {
  return createSelector2(
    selectErrorsState,
    (errors) => errors[type] || null
  );
};
var extractError = (err) => {
  const errors = Object.values(err || []);
  if (!errors.length)
    return "";
  const errorMessage = errors.pop();
  if (typeof errorMessage === "string")
    return errorMessage;
  return extractError(errorMessage);
};
var selectLastError = (type) => {
  return createSelector2(
    selectErrors(type),
    (errors) => extractError(errors)
  );
};
var selectGenericErrors = (type) => {
  return createSelector2(
    selectErrorsState,
    (errors) => {
      const err = errors[type] || {};
      return err.apiGenericError || err.frontGenericError;
    }
  );
};
export {
  AdminRights,
  AuthActionTypes,
  BusinessType,
  Countries,
  Days,
  ExperienceExtraData,
  Languages,
  LyraStatus,
  NUANCIER_TAG_NAME,
  OrderDetailedStatus,
  OrderStatus,
  PRODUCT_VAT,
  PaymentMode,
  ProductType,
  PromotionType,
  STORAGE_DATA_EXPIRATION_KEY,
  ShippingModes,
  UserRights,
  authReducer,
  authSagas,
  choicesLeft,
  choicesScaleLeft,
  computeCartPrices,
  computeProductExcludedPrices,
  convertFromVatToHT,
  convertToCents,
  convertToEuros,
  convertToKilos,
  convertToVat,
  createExpirationInterceptor,
  editProductCart,
  extractChoicesFromProducts,
  extractProducts,
  extractProductsToUpdate,
  extractResponseData,
  extractResponseErrors,
  findSameProductIndex,
  freeChoicesLeft,
  fromArrayToMap,
  fromMapToArray,
  getBusinessTypeName,
  getChoiceProductPrice,
  getChoiceProductWeight,
  getChoicesCount,
  getChoicesQuantity,
  getChoicesScaleCount,
  getCountryByCode,
  getElementScaleGroup,
  getEnvVar,
  getFreeChoices,
  getFreeIncluded,
  getItem,
  getLowerRangeProductPrice,
  getOrderPaymentTraduction,
  getOrderStatusAsOptions,
  getOrderStatusColor,
  getOrdersTraduction,
  getPathname,
  getProductElementPrice,
  getProductElementPriceVat,
  getProductPrice,
  getProductPriceVat,
  getProductTotalWeightWithFree,
  getProductWeight,
  getProductsTotalWeightWithFree,
  getProductsTotalWeightWithFreeDecagrammes,
  getRangeFromQuantity,
  getRangeProductPrice,
  getTotalPrice,
  getTotalPriceByCountry,
  getTotalPriceByCountryForQuotation,
  getTotalPriceForQuotation,
  getTotalProductPrice,
  handleExpired,
  haveFreeProducts,
  haveIncludedProducts,
  isAllSameChoice,
  isB2B,
  isB2C,
  isBrowser,
  isCartFreeProductsValid,
  isCartProductValid,
  isChoiceProduct,
  isChoiceProductStockValid,
  isChoiceProductType,
  isChoiceStockValid,
  isChoicesScaleValid,
  isChoicesValid,
  isElementValid,
  isFreeChoicesStockValid,
  isFreeChoicesValid,
  isGroupProduct,
  isProductStockValid,
  isPromoCart,
  isPromoFixed,
  isPromoPercent,
  isPromoWithAmount,
  isQuantityValid,
  isSameChoice,
  isSimpleGroupStockValid,
  isStockValid,
  isWeightProduct,
  loginAttempt,
  loginFailure,
  loginSuccess,
  logoutAttempt,
  logoutFailure,
  logoutSuccess,
  mergeMaps,
  mergeMapsWithExisting,
  metaStateReducer,
  objectToFormData,
  productEqual,
  removeItem,
  selectErrors,
  selectGenericErrors,
  selectIsAuthenticated,
  selectLastError,
  selectPending,
  setItem,
  sync,
  updateExpiration
};
